<template>
  <div dark>
    <div style="width: 100%; position: relative">
      <AppleSignInDialogView
        :appleSignInDialog="appleSignInDialog"
        @onClickConfirm="onClickConfirm"
        @onClickCancel="onClickCancel"
      />
    </div>
    <div
      class="font-g14-18 gray400--text text-right rixgo-bold pt-10 pr-10 pl-10"
      @click="skipAppleSignInMoreInformation"
    >
      건너뛰기
    </div>
    <div class="font-g16-24 gray900--text text-center rixgo-bold pt-25">
      내 취향과 스타일을 추천 받아보세요
    </div>
    <div class="font-g13-18 gray900--text text-center rixgo-regular pt-4">
      성별 / 나이에 맞춘 개인화 상품 추천과<br />
      다양한 이벤트 혜택을 드립니다.
    </div>
    <div class="ml-10 mr-10 pt-20 fill-height">
      <div class="pb-20">
        <div class="font-g16-18 gray900--text text-left rixgo-bold pb-8">
          성별
        </div>
        <v-row>
          <v-col
            v-for="(item, index) in genderSelect"
            :key="item.index"
            cols="6"
          >
            <v-btn
              depressed
              :class="`fill-width font-g14-20 rixgo-semibold ${
                selectGenderIndex === index
                  ? 'black white--text'
                  : 'white gray900--text'
              }`"
              height="55"
              style="border: 1px solid #eaebed !important"
              @click="onClickSelectGender(index)"
            >
              {{ item }}
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <div>
        <div class="font-g16-18 gray900--text text-left rixgo-bold pb-8">
          생년월일
        </div>
        <div @click="openPickerDialog">
          <v-text-field
            readonly
            :disabled="disableBirthdayField"
            class="font-g14-20 rixgo-regular"
            type="string"
            background-color="#F4F4F4"
            solo
            style="border-radius: 4px"
            flat
            height="55"
            :value="`${
              targetYear === '' && targetMonth === '' && targetYear === ''
                ? '생년월일을 입력해 주세요.'
                : targetYear + '년 ' + targetMonth + '월 ' + targetDay + '일'
            }`"
          ></v-text-field>
        </div>
      </div>
    </div>
    <div class="btn-bottom">
      <v-btn
        class="disabled-btn-color-white fill-width font-g14-20 rixgo-bold blue white--text"
        height="55"
        :disabled="computedSubmitBtn"
        width="91%"
        @click="signInComplete"
        >쇼핑 시작하기</v-btn
      >
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import AppleSignInDialogView from './AppleSingInDialog.vue'
import { PutAppleUserInformation } from '../../../../src/api/accountAPI/UsersAPI'
import { setAccessToken } from '../../../../src/plugins/axios'
export default Vue.extend({
  name: 'AppleLoginMoreInformationView',
  components: { AppleSignInDialogView },
  data() {
    return {
      selectGenderIndex: -1,
      genderSelect: ['여자', '남자'],
      targetYear: '',
      targetMonth: '',
      targetDay: '',
      disableBirthdayField: true,
      appleSignInDialog: false,
    }
  },
  computed: {
    computedSubmitBtn() {
      let flag = true
      if (this.selectGenderIndex > -1 && this.targetYear !== '') {
        flag = false
      }
      return flag
    },
  },
  methods: {
    skipAppleSignInMoreInformation() {
      window.location.href = `${process.env.VUE_APP_CELLOOK_URL}/marketing-agree`
    },
    async signInComplete() {
      const accessToken = localStorage.getItem('accessToken')
      if (accessToken) {
        setAccessToken(String(accessToken))
      } else {
        this.$router.push('/')
      }
      const gender = this.selectGenderIndex === 0 ? 'F' : 'M'
      const data = {
        gender: gender,
        birthDay: this.targetMonth + this.targetDay,
        birthYear: this.targetYear,
      }
      //CALL API
      await PutAppleUserInformation(data)
        .then((result) => {
          console.log(result)
          window.location.href = `${process.env.VUE_APP_CELLOOK_URL}/marketing-agree`
        })
        .catch((error) => {
          console.log(error)
        })
    },
    onClickSelectGender(index) {
      if (!this.appleSignInDialog) {
        if (this.selectGenderIndex === index) {
          this.selectGenderIndex = -1
        } else {
          this.selectGenderIndex = index
        }
      }
    },
    openPickerDialog() {
      this.targetYear = ''
      this.targetMonth = ''
      this.targetDay = ''
      this.appleSignInDialog = true
      this.disableBirthdayField = true
    },
    onClickConfirm(targetYear, targetMonth, targetDay) {
      if (targetMonth < 10) {
        targetMonth = String('0' + targetMonth)
      } else {
        targetMonth = String(targetMonth)
      }
      if (targetDay < 10) {
        targetDay = String('0' + targetDay)
      } else {
        targetDay = String(targetDay)
      }
      this.targetYear = String(targetYear)
      this.targetMonth = targetMonth
      this.targetDay = targetDay
      this.appleSignInDialog = false
      this.disableBirthdayField = false
    },
    onClickCancel() {
      this.appleSignInDialog = false
    },
  },
})
</script>
<style scoped>
.btn-bottom {
  position: absolute;
  text-align: center;
  bottom: 30px;
  width: 100%;
}
</style>
