<template>
  <div
    v-if="appleSignInDialog"
    class=""
    style="
      position: absolute;
      border-radius: 1px solid black !important;
      -webkit-appearance: none !important;
      z-index: 300;
      width: 100%;
      height: 100%;
      margin-top: 45%;
      padding-left: 15%;
      padding-right: 15%;
    "
  >
    <v-card flat>
      <div
        class="pt-10 pb-10"
        style="box-shadow: rgba(0, 0, 0, 0.6) 0 0 0 9999px"
      >
        <div class="font-g20-28 rixgo-extrabold text-center">
          {{ currentYear }}년 {{ currentMonth }}월 {{ currentDay }}일
        </div>
        <div class="picker-group pl-12 pr-12">
          <ScrollPicker v-model="currentYear" :options="years" />
          <ScrollPicker v-model="currentMonth" :options="months" />
          <ScrollPicker v-model="currentDay" :options="days" />
        </div>
        <div class="d-flex justify-end text-end pl-12 pr-12">
          <v-btn
            class="font-g13-14 white black--text pr-10"
            rounded
            text
            height="44"
            width="80"
            @click="onClickCancel"
          >
            취소
          </v-btn>
          <v-btn
            class="font-g13-14 blue white--text"
            rounded
            text
            height="44"
            width="80"
            @click="onClickConfirm"
          >
            확인
          </v-btn>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import Vue from 'vue'
import { ScrollPicker } from 'vue-scroll-picker'
import 'vue-scroll-picker/dist/style.css'
export default Vue.extend({
  name: 'AppleSignInDialogView',
  components: {
    ScrollPicker,
  },
  props: {
    appleSignInDialog: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentYear: new Date().getFullYear() - 29,
      currentMonth: 1,
      currentDay: 1,
    }
  },
  computed: {
    years() {
      const currYear = new Date().getFullYear() - 15
      const lastYear = new Date().getFullYear() - 70
      return Array.from(
        { length: currYear - lastYear + 1 },
        (_, index) => lastYear + index
      )
    },
    months() {
      return Array.from({ length: 12 }, (_, index) => index + 1)
    },
    days() {
      const lastDay = new Date(this.currentYear, this.currentMonth, 0).getDate()
      return Array.from({ length: lastDay }, (_, index) => index + 1)
    },
  },
  methods: {
    onClickCancel() {
      this.currentYear = new Date().getFullYear() - 29
      this.currentMonth = 1
      this.currentDay = 1
      this.$emit('onClickCancel')
    },
    onClickConfirm() {
      this.$emit(
        'onClickConfirm',
        this.currentYear,
        this.currentMonth,
        this.currentDay
      )
    },
  },
})
</script>
<style lang="scss" scoped>
.line {
  border-bottom: 1px solid #f4f4f4;
}
.picker-group {
  display: flex;
}
</style>
