var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{attrs:{"dark":""}},[_c('div',{staticStyle:{"width":"100%","position":"relative"}},[_c('AppleSignInDialogView',{attrs:{"appleSignInDialog":_vm.appleSignInDialog},on:{"onClickConfirm":_vm.onClickConfirm,"onClickCancel":_vm.onClickCancel}})],1),_c('div',{staticClass:"font-g14-18 gray400--text text-right rixgo-bold pt-10 pr-10 pl-10",on:{"click":_vm.skipAppleSignInMoreInformation}},[_vm._v(" 건너뛰기 ")]),_c('div',{staticClass:"font-g16-24 gray900--text text-center rixgo-bold pt-25"},[_vm._v(" 내 취향과 스타일을 추천 받아보세요 ")]),_vm._m(0),_c('div',{staticClass:"ml-10 mr-10 pt-20 fill-height"},[_c('div',{staticClass:"pb-20"},[_c('div',{staticClass:"font-g16-18 gray900--text text-left rixgo-bold pb-8"},[_vm._v(" 성별 ")]),_c('v-row',_vm._l((_vm.genderSelect),function(item,index){return _c('v-col',{key:item.index,attrs:{"cols":"6"}},[_c('v-btn',{class:`fill-width font-g14-20 rixgo-semibold ${
              _vm.selectGenderIndex === index
                ? 'black white--text'
                : 'white gray900--text'
            }`,staticStyle:{"border":"1px solid #eaebed !important"},attrs:{"depressed":"","height":"55"},on:{"click":function($event){return _vm.onClickSelectGender(index)}}},[_vm._v(" "+_vm._s(item)+" ")])],1)}),1)],1),_c('div',[_c('div',{staticClass:"font-g16-18 gray900--text text-left rixgo-bold pb-8"},[_vm._v(" 생년월일 ")]),_c('div',{on:{"click":_vm.openPickerDialog}},[_c('v-text-field',{staticClass:"font-g14-20 rixgo-regular",staticStyle:{"border-radius":"4px"},attrs:{"readonly":"","disabled":_vm.disableBirthdayField,"type":"string","background-color":"#F4F4F4","solo":"","flat":"","height":"55","value":`${
            _vm.targetYear === '' && _vm.targetMonth === '' && _vm.targetYear === ''
              ? '생년월일을 입력해 주세요.'
              : _vm.targetYear + '년 ' + _vm.targetMonth + '월 ' + _vm.targetDay + '일'
          }`}})],1)])]),_c('div',{staticClass:"btn-bottom"},[_c('v-btn',{staticClass:"disabled-btn-color-white fill-width font-g14-20 rixgo-bold blue white--text",attrs:{"height":"55","disabled":_vm.computedSubmitBtn,"width":"91%"},on:{"click":_vm.signInComplete}},[_vm._v("쇼핑 시작하기")])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"font-g13-18 gray900--text text-center rixgo-regular pt-4"},[_vm._v(" 성별 / 나이에 맞춘 개인화 상품 추천과"),_c('br'),_vm._v(" 다양한 이벤트 혜택을 드립니다. ")])
}]

export { render, staticRenderFns }