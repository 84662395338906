<template>
  <AppleLoginMoreInformationView />
</template>
<script>
import AppleLoginMoreInformationView from '../../views/login/AppleLoginMoreInformation.vue'
export default {
  name: 'AppleLoginMoreInformation',
  components: {
    AppleLoginMoreInformationView,
  },
  methods: {},
}
</script>
